import React, { useEffect } from 'react'
import Navbar from './components/navbar'
import Wlogo from './W2021.png';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";
import Africa from './africa.png';
import Screenshot from './phone.png';
import googlePlay from './googlePlay.png'
import appStore from './APPSTORE.png'
// import styled, { keyframes } from 'styled-components';
// import {bounce} from 'react-animations'
import Aos from 'aos'
import WebFont from 'webfontloader';
import 'aos/dist/aos.css'
import { Animated } from "react-animated-css";
import friends from './friends.png'
import family from './family.png'

import './App.css';

// const Bounce = styled.div`animation: 2s ${keyframes`${bounce}`}infinite`;

function App() {

  useEffect(() => {
    Aos.init({ duration: 2000 })
  }, [])

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Montserrat']
      }
    });
  }, []);
  return (
    <div className="main">
      <Navbar />
      <BrowserView>
     
      <div className="App-section">
        <div className="App">
          <div className="App-header">
            <div>
              <p className='heading'>
                The community of communities.</p>
              <p className='subheading'>
                Connect, share, and co-create together with your customers and the world on Wakanda.
              </p>
              <small className='subheading'>
                Communities - Market - Health - Jobs - Events - More
              </small>
            </div>

            <div className='list-div'>
              <a href="https://play.google.com/store/apps/details?id=com.african_founders.Wakanda" target='_blank'><img src={googlePlay} width='150' alt='google play' /></a>
              <a href="https://apps.apple.com/ng/app/wakanda-market/id1451309384" target='_blank'>&nbsp;<img src={appStore} width='150' alt='appstore' /></a>
            </div>
          </div>
          <div className="screenshot" data-aos="fade-up">
            <img src={Screenshot} height="550" alt="logo" />
          </div>
        </div>

        {/* <div className="main-screenshot">
          <div data-aos="fade-up" style={{
            backgroundImage: `url(${Screenshot})`,
            height: 500,
            backgroundPosition: 'center center',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat'
          }} className='image-holder'>
          </div>
        </div> */}

        </div>
      </BrowserView>
      <MobileView> 
        <div className="">
          
           
            <Animated animationIn="fadeIn" animationOut="fadeIn" animationInDuration={2000} animationOutDuration={2000} isVisible={false}>
              <div className='parent'>
                <img src={Wlogo} width="200" alt="logo" />
                <p className='heading-mobile'>
                  The community of communities.
        </p>
                <p className='subheading-mobile'>
                  Connect, share, and co-create together with your customers and the world on Wakanda.
        </p>
                <small className='subheading-mobile'>
                  Communities - Market - Health - Jobs - Events - More
        </small>
              </div>
            </Animated>

            <div className='list-div'>
              <a href="https://play.google.com/store/apps/details?id=com.african_founders.Wakanda" target='_blank'><img src={googlePlay} width='150' alt='google play' /></a>
              <a href="https://apps.apple.com/ng/app/wakanda-market/id1451309384" target='_blank'>&nbsp;<img src={appStore} width='150' alt='appstore' /></a>
            </div>

         
          <main>
            <div data-aos="fade-up" style={{
              backgroundImage: `url(${Screenshot})`,
              height: 500,
              backgroundPosition: 'center center',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat'
            }} className='image-holder'>
            </div>
          </main>

        </div>
      </MobileView>
      <section className="communities">
        <div data-aos="fade-up"  >
          <h1 className='community-header'>Communities at heart </h1>
          <p className='community-text' >
            Wakanda helps communities share, organise, exchange and create impact/ do business together within the ecosystem,
            Communities can be businesses, traditional markets, initiatives or movements, professional and local associations and networks,
            religious groups, organizations and more...
          </p>
          <p className='community-text' >
            Communities in a specific field, be it healthcare, education, artisans will become community networks - a professional social network / a community of communities.
          </p></div>
        <div className='App-header-2'>
          <img src={friends} width="150" className="friends" alt="logo" />
          <img src={family} width="150" className="family" alt="logo" />
        </div>
      </section>
      <section className="africa">
        <div data-aos="fade-up"  >

          <div className="App-header-3">
            <h1 className='community-africa'>For a better Africa </h1>
            <img src={Africa} alt="logo" /></div>

          <p className='community-textafrica' >
            As a technology company, we innovate for our users with one core belief: <em>Life can be better.</em>&nbsp;
            Despite increasing access and advancing technology, many parts of Africa prefer to remain disconnected - due to a lack of trust in the greater system.
            Therefore we have created Wakanda. One tailored "super-app" with an African way of building trust at its heart: communities.
          </p></div>

      </section>
      <div className="App-header-4">
        <button className="explore-button">Explore Features</button>
      </div>
      <section className="footer">

        <p className='community-text' >
          2021 <i class="fa fa-copyright" aria-hidden="true"></i> Wakanda
          </p>

      </section>

    </div>

  );
}

export default App;

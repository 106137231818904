import React, { useState } from 'react'
import '../App.css'
import Wlogo from '../W2021.png';
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";

const Navbar = () => {

    const [showLinks, setShowLinks] = useState(false)


    return (
        <nav>
            <BrowserView><div className="wlogo"><img src={Wlogo} width="120" alt="logo" /></div>
                <div className="leftSide">
                    <div className="links" id={showLinks ? "hidden" : ""} >
                        <ul>
                            <li> <a href="#" className='active' >Features</a></li>
                            <li> <a href="http://wakandamarket.com/app" >Download</a></li>
                            <li> <a href="#" >Security</a></li>
                        </ul>
                    </div>
                    <label id="icon" >
                        <i className={showLinks ? 'fas fa-times' : 'fas fa-bars'} onClick={() => setShowLinks(!showLinks)} ></i>
                    </label>
                </div>
            </BrowserView>
            <MobileView>
                <div className="leftSide">
                    <div className="links" id={showLinks ? "hidden" : ""} >
                        <ul>
                            <li> <a href="#" className='active' >Features</a></li>
                            <li> <a href="#" >Download</a></li>
                            <li> <a href="#" >Security</a></li>
                        </ul>
                    </div>
                    <label id="icon" >
                        <i className={showLinks ? 'fas fa-times' : 'fas fa-bars'} onClick={() => setShowLinks(!showLinks)} ></i>
                    </label>
                </div>
            </MobileView>
        </nav>
    );
}

export default Navbar;
